import React from "react";

import TemplateWrapper from "../components/templateWrapper";
import Head from "../components/head";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <TemplateWrapper>
    <Head title={"Pagina niet gevonden (404)"} />
    <Helmet>
      <title>Test</title>
    </Helmet>
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="content-inner__left">
          <h1>Pagina niet gevonden (404)</h1>
          <p>Helaas is deze pagina niet beschikbaar</p>
          <p>
            <Link to="/">Klik hier om naar de voorpagina te gaan</Link>
          </p>
        </div>
      </div>
    </div>
  </TemplateWrapper>
);

export default NotFoundPage;
